import { graphql } from 'gatsby'
import React from 'react'
import Hero from '../components/hero'

// Load Page Contents
import contentEn from '../../content/pages/term-condition.en.yaml'
import contentId from '../../content/pages/term-condition.id.yaml'
import { useTranslation } from 'react-i18next'

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default function TermConditionPage() {
  const { i18n } = useTranslation()
  const [content, setContent] = React.useState(contentEn)

  React.useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <Hero>
        <h1 className="uk-heading-small uk-text-center">{content.title}</h1>
        {/* <p className="uk-width-xlarge">
          Interested in how Jasnita can help your company with user experience? Leave you information and a dedicated
          specialist will be in touch!
        </p> */}
      </Hero>

      <section
        className="uk-margin-large"
        // style={{ marginTop: -100 }}
      >
        <div className="uk-container">
          <div className="uk-width-2xlarge@m uk-margin-auto">
            <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
          </div>
        </div>
      </section>
    </>
  )
}
